import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Title from "../components/Headings/Title"
import SignalsTab from "../components/Signals/SignalsTab"
import ECGWaveform from "../components/Signals/ECGWaveform"
import FourierSeries from "../components/Signals/FourierSeries"
import SineWaveform from "../components/Signals/SineWaveform"

const AllSignals = [
  {
    name: "SineWaveform",
    signal: <SineWaveform />,
  },
  {
    name: "FourierSeries",
    signal: <FourierSeries />,
  },
  {
    name: "ECGWaveform",
    signal: <ECGWaveform />,
  },
]

class Signals extends React.Component {
  render() {
    return (
      <Layout>
        <Seo title="Signals" />
        <section className="signals-section">
          <Title>Signals</Title>
          <div className="container py-5">
            <SignalsTab data={AllSignals} />
          </div>
        </section>
      </Layout>
    )
  }
}

export default Signals
