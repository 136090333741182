import React, { useState, useEffect } from "react"
import styled from "styled-components"

const Pagination = styled.div`
  height: auto;
  display: grid;
  grid-gap: 20px;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  > span {
    font-size: 16px;
    text-transform: uppercase;
  }
`

export default function FourierSeries() {
  const [degree, setDegree] = useState(0)
  const [n, setN] = useState(1)

  useEffect(() => {
    const id = setInterval(() => {
      setDegree(c => {
        if (c < 360) {
          return c + 1
        } else {
          return 0
        }
      })
    }, 30)
    return () => clearInterval(id)
  }, [])

  const onSlider = () => {
    var slider = document.getElementById("myRange")
    if (slider.value % 2 > 0) {
      setN(slider.value)
    }
  }

  return (
    <div className="card signal border-primary shadow mb-4">
      <div className="card-header">
        <Pagination>
          <span>Fourier Series</span>

          <span>
            <div className="text-center">n = {n}</div>
            <div className="text-center">
              <input
                type="range"
                min="1"
                max="23"
                defaultValue="1"
                step="2"
                className="slider"
                id="myRange"
                onInput={onSlider}
              />
            </div>
          </span>
        </Pagination>
      </div>
      <FourierDraw degree={degree} n={n} />
    </div>
  )
}

const FourierDraw = ({ degree, n }) => (
  <svg width="1200" height="420" viewBox="0 0 1024 240">
    <g transform="translate(20 0)">
      {/* circle */}
      {circleItems(n, degree)}

      {/* sine */}
      <g transform="translate(460 0)">
        <line className="gray" x1="0" y1="100" x2="360" y2="100" />
        <polyline
          points={Array.from({ length: 360 }, (value, key) => {
            return key + " " + sumSine(n, key, degree)
          })}
        />
      </g>

      {/* connected line */}
      <line
        className="connectedLine"
        x1={sumCircleCenterX(n, degree) + 110}
        y1={sumCircleCenterY(n, degree)}
        x2={460}
        y2={sumSine(n, 360, degree)}
      />
    </g>
  </svg>
)

const circleItems = (n, degree) => {
  const items = []
  n = Number(n)
  const len = (n + 1) / 2
  Array.from({ length: len }, (value, key) => {
    return 2 * (key + 1) - 1
  }).forEach(i => {
    items.push(
      <g key={"fou_circle_" + i} transform="translate(110 0)">
        <circle
          className="gray"
          cx={i === 1 ? 100 : sumCircleCenterX(i - 2, degree)}
          cy={i === 1 ? 100 : sumCircleCenterY(i - 2, degree)}
          r={(4 / (i * Math.PI)) * 100}
        />
        <line
          x1={i === 1 ? 100 : sumCircleCenterX(i - 2, degree)}
          y1={i === 1 ? 100 : sumCircleCenterY(i - 2, degree)}
          x2={sumCircleCenterX(i, degree)}
          y2={sumCircleCenterY(i, degree)}
        />
      </g>
    )
  })

  return items
}

const sumSine = (n, key, degree) => {
  n = Number(n)
  let sum = 0
  for (let i = n; i > 0; i -= 2) {
    sum +=
      ((Math.sin(i * dToR(key) - i * dToR(degree)) * 4) / (i * Math.PI)) * 100
  }

  sum += 100
  return sum
}

//degreeToRad
const dToR = degree => {
  return (degree / 180) * Math.PI
}

const sumCircleCenterX = (n, degree) => {
  n = Number(n)
  let sum = 0
  for (let i = n; i > 0; i -= 2) {
    sum += ((Math.cos(i * dToR(degree)) * 4) / (i * Math.PI)) * 100
  }
  sum += 100
  return sum
}

const sumCircleCenterY = (n, degree) => {
  n = Number(n)
  let sum = 0
  for (let i = n; i > 0; i -= 2) {
    sum += ((-Math.sin(i * dToR(degree)) * 4) / (i * Math.PI)) * 100
  }
  sum += 100
  return sum
}
