import React from "react"

function SignalsList(props) {
  const Clicked = () => {
    props.onClick(props.index)
  }
  return (
    <div
      key={props.index}
      className="card shadow mb-1"
      style={{
        backgroundColor: "#" + Math.random().toString(16).slice(-6) + "10",
      }}
    >
      <div
        onClick={Clicked}
        role="presentation"
        className={
          props.activeTabId === props.index
            ? "btn btn-secondary fw-bold"
            : "btn btn-outline-primary "
        }
      >
        {props.data.name}
      </div>
    </div>
  )
}

export default SignalsList
