import React, { useState, useEffect } from "react"
import styled from "styled-components"

const Pagination = styled.div`
  height: auto;
  display: grid;
  grid-gap: 20px;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  > span {
    font-size: 16px;
    text-transform: uppercase;
  }
`

export default function SineWaveform() {
  const [degree, setDegree] = useState(0)
  const [n, setN] = useState(1)

  useEffect(() => {
    const id = setInterval(() => {
      setDegree(c => {
        if (c < 360) {
          return c + 1
        } else {
          return 0
        }
      })
    }, 30)
    return () => clearInterval(id)
  }, [])

  const onSlider = () => {
    var slider = document.getElementById("myRange2")
    if (slider.value > 0) {
      setN(slider.value)
    }
  }
  const sin = value => -Math.sin(((value * n) / 180) * Math.PI)
  return (
    <div className="card signal border-primary shadow mb-4">
      <div className="card-header">
        <Pagination>
          <span>Sine Waveform</span>

          <span>
            <div className="text-center">n = {n}</div>
            <div className="text-center">
              <input
                type="range"
                min="1"
                max="90"
                defaultValue="1"
                step="1"
                className="slider"
                id="myRange2"
                onInput={onSlider}
              />
            </div>
          </span>
        </Pagination>
      </div>
      <svg width="1200" viewBox="0 -120 1024 240">
        <g transform="translate(20 0)">
          <polyline
            points={Array.from(
              { length: degree },
              (v, d) => `${d * 2} ${sin(d) * 100}`
            )}
          />
        </g>
        <text x={degree * 2 + 30} y={sin(degree) * 100}>
          {parseFloat(Math.sin((degree / 180) * Math.PI)).toFixed(4)}
        </text>
      </svg>
    </div>
  )
}
