import React from "react"

function SignalsContent(props) {
  return (
    <div
      key={props.index}
      style={
        props.activeTabId === props.index
          ? { display: "block" }
          : { display: "none" }
      }
    >
      {props.data.signal}
    </div>
  )
}

export default SignalsContent
