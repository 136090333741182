import React, { useState, useEffect } from "react"
import styled from "styled-components"

const Pagination = styled.div`
  height: auto;
  display: grid;
  grid-gap: 20px;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  > span {
    font-size: 16px;
    text-transform: uppercase;
  }
`

export default function ECGWaveform() {
  const [degree, setDegree] = useState(0)
  const [n, setN] = useState(1)

  useEffect(() => {
    const id = setInterval(() => {
      setDegree(c => {
        if (c < 360) {
          return c + 1
        } else {
          return 0
        }
      })
    }, 30)
    return () => clearInterval(id)
  }, [])

  const onSlider = () => {
    var slider = document.getElementById("myRange3")
    if (slider.value > 0) {
      setN(slider.value)
    }
  }
  const p_wave = value =>
    -Math.exp(Math.pow(value - 90, 2) / -Math.pow(20, 2)) * 3
  const q_wave = value =>
    -Math.exp(Math.pow(value - 160, 2) / -Math.pow(5, 2)) * -5
  const r_wave = value =>
    -Math.exp(Math.pow(value - 170, 2) / -Math.pow(5, 2)) * 30
  const s_wave = value =>
    -Math.exp(Math.pow(value - 180, 2) / -Math.pow(5, 2)) * -7.5
  const t_wave = value =>
    -Math.exp(Math.pow(value - 250, 2) / -Math.pow(30, 2)) * 5
  const ecg = value =>
    p_wave(value) +
    q_wave(value) +
    r_wave(value) +
    s_wave(value) +
    t_wave(value)

  const sumECG = value => {
    let sum = 0
    for (let i = 0; i < n; i++) {
      sum += ecg(value * n - i * 360)
    }
    return sum
  }

  return (
    <div className="card signal border-primary shadow mb-4">
      <div className="card-header">
        <Pagination>
          <span>ECG Waveform</span>
          <span>
            <div className="text-center">n = {n}</div>
            <div className="text-center">
              <input
                type="range"
                min="1"
                max="5"
                defaultValue="1"
                step="1"
                className="slider"
                id="myRange3"
                onInput={onSlider}
              />
            </div>
          </span>
        </Pagination>
      </div>
      <svg width="1200" viewBox="0 -180 1024 240">
        <g transform="translate(20 0)">
          <polyline
            points={Array.from(
              { length: degree },
              (v, d) => `${d * 2} ${sumECG(d) * 5}`
            )}
          />
        </g>
        <text x={degree * 2 + 30} y={sumECG(degree) * 5}>
          {parseFloat(-sumECG(degree)).toFixed(4)}
        </text>
      </svg>
    </div>
  )
}
