import React, { useState } from "react"
import SignalsList from "./SignalsList"
import SignalsContent from "./SignalsContent"

function SignalsTab(props) {
  const [activeTabId, setActiveTabId] = useState(0)

  function btnClick(id) {
    setActiveTabId(id)
  }

  return (
    <div className="row justify-content-center">
      <div className="col-lg-3">
        {props.data.map((name, index) => (
          <SignalsList
            key={index}
            onClick={btnClick}
            data={name}
            index={index}
            activeTabId={activeTabId}
          />
        ))}
      </div>
      <div className="col-lg-9">
        {props.data.map((details, index) => (
          <SignalsContent
            data={details}
            key={index}
            index={index}
            activeTabId={activeTabId}
          />
        ))}
      </div>
    </div>
  )
}

export default SignalsTab
